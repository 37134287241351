body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
App {
  margin: 0px 20px 0px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  font-size: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  padding: 5px;
}

button {
  max-width: 50%;
  font-size: 24px;
  padding: 10px;
  margin: 15px auto 15px auto;
}
.warn {
  font-weight: 800;
  color: red;
  background-color: white;
}

div50 {
  display: block;
  max-width: 50%;
  padding: 15px;
}
